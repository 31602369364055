import * as React from 'react'
import { Link, navigate } from 'gatsby'
import '~/style/common.scss'
import { css } from '@emotion/react'
import AppStyle from '~/libs/AppStyle'
import AppColor from '~/libs/AppColor'
import { AppLayout } from '~/components/AppLayout'
import AppSize from '~/libs/AppSize'

const ContactCompletePage = () => {
  return (
    <AppLayout title={'[完了]お問い合わせフォーム'}>
      <div css={myPageCss}>
        <div>
          <h1 css={titleCss}>お問い合わせフォーム</h1>
          <p css={messageCss}>
            お問い合わせいただきありがとうございました。
            <br />
            ご記入いただいた内容を元に当方で検討を済ませてから
            <br />
            メールにてご返信させていただきます。
          </p>
          <p css={subMessageCss}>
            （注）たった1人の会社ですのでお返事にはお時間がかかる場合もございます。
            <br />
            ご寛容にお待ちいただけますと幸いです。
          </p>
          <div>
            <Link to="/mode-select" css={AppStyle.primaryBtn}>
              TOP
            </Link>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default ContactCompletePage

const myPageCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  overflow-y: scroll;
  padding: 0 16px;
`

const titleCss = css`
  font-size: 24px;
  display: inline-block;
  padding: 0 16px;
  border-bottom: 5px solid ${AppColor.readable};
  padding: 0 40px 4px 40px;
  @media (min-width: ${AppSize.sm}) {
    font-size: 30px;
  }
`

const messageCss = css`
  font-size: 14px;
  @media (min-width: ${AppSize.sm}) {
    font-size: 16px;
  }
`

const subMessageCss = css`
  font-size: 12px;
  margin: 32px 0 64px 0;
`
