import { css } from '@emotion/react'
import AppColor from '~/libs/AppColor'

export default class AppStyle {
  public static readonly link = css`
    color: ${AppColor.readable};
  `

  public static readonly primaryBtn = css`
    display: inline-block;
    background-color: ${AppColor.readable};
    text-align: center;
    padding: 16px 24px;
    color: #fff;
    font-size: 16px;
    border: none;
    min-width: 220px;
  `
}
